<template>
  <div>
    <v-app-bar flat >
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click="drawer = true"></v-app-bar-nav-icon>
      <v-container v-if="!$vuetify.breakpoint.mobile">
        <v-row no-gutters>
          <v-col cols="5">
            <v-btn to="/" text>
              <img style="height:30px; margin-right: 10px" alt="Logo der Website Gemeidehaushaus.digital" contain src="@/assets/logo-opengov-kreis.png" >Gemeindehaus.digital
            </v-btn>
          </v-col>
          <!--<v-spacer />
          <v-col>
            <a href="https:badendbach.gemeindehaus.digital/leichte-sprache">
              <img src="@/assets/signet-leichte-sprache.png" alt="Logo für Leichte Sprache" class="easy-language"/>
            </a>
          </v-col>-->
          
        </v-row>
      </v-container>
      <v-container v-if="$vuetify.breakpoint.mobile" class="text-center">
        Gemeindehaus.digital
      </v-container>
    </v-app-bar>
  
    <v-navigation-drawer v-model="drawer" app temporary>
        <v-list nav>
          <v-list-item>
            <v-btn class="ma-2" text to="/">Gemeindehaus.digital</v-btn>
          </v-list-item>
          <!--<v-list-item>
            <router-link to="/leichte-sprache">
              <img src="@/assets/signet-leichte-sprache.png" alt="Logo für Leichte Sprache" class="easy-language"/>
            </router-link>
          </v-list-item>-->
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>

  export default {
    name: 'Header',
    components: {},
    data: () => ({
      drawer: false
    }),
  }
</script>

<style lang="scss">
  @import "@/assets/_variables.scss";

  .easy-language {
    max-width: 44px;
    height: 44px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .easy-language {
      max-width: 30px;
      height: 30px;
    }
  }
</style>