<template>
  <v-app>
    
    <Header />
    
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-img src="@/assets/steffen-lemmerzahl-3O8k1_rTzA8-unsplash.jpg" height="400px" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
          Herzlich Willkommen im digitalen Gemeindehaus!
          <br /> 
          Durch einen Kick auf das Logo Ihrer Gemeinde werden Sie direkt in Ihr digitales Gemeindehaus weitergeleitet. 
          <br />
          Wir freuen uns über Ihren Besuch!
        </v-col>
        </v-row>
        <v-row justify="space-around" class="ma-4">
          <v-col cols="10" sm="4">
            <a href="https://badendbach.gemeindehaus.digital">
             <v-img src="@/assets/logo-bad-endbach.svg" alt="Bad Endbach" contain height="200px"/>
            </a>
          </v-col>
          <v-col cols="10" sm="4">
            <a href="https://breidenbach.gemeindehaus.digital">
              <v-img src="@/assets/Web_Logo-Breidenbach_V2_ohne_de.png" alt="Breidenbach" contain height="200px"/>
            </a>
          </v-col>
        </v-row>
      </v-container>

    </v-main>

    <Footer />
  </v-app>
</template>

<script>
  import Footer from './components/Footer.vue'
  import Header from './components/Header.vue'

  export default {
    components: {
      Footer,
      Header
    },
    data: () => ({ 
      drawer: null
    }),
  }
</script>

<style lang="scss">

/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('@/assets/fonts/roboto-v29-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('@/assets/fonts/roboto-v29-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@/assets/fonts/roboto-v29-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('@/assets/fonts/roboto-v29-latin-100.woff') format('woff'), /* Modern Browsers */
       url('@/assets/fonts/roboto-v29-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@/assets/fonts/roboto-v29-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('@/assets/fonts/roboto-v29-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('@/assets/fonts/roboto-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@/assets/fonts/roboto-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('@/assets/fonts/roboto-v29-latin-300.woff') format('woff'), /* Modern Browsers */
       url('@/assets/fonts/roboto-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@/assets/fonts/roboto-v29-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('@/assets/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@/assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('@/assets/fonts/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('@/assets/fonts/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@/assets/fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('@/assets/fonts/roboto-v29-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('@/assets/fonts/roboto-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@/assets/fonts/roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('@/assets/fonts/roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */
       url('@/assets/fonts/roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@/assets/fonts/roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
</style>