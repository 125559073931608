<template>
  <div class="FOOTER">
    <v-footer padless color="transparent">

      <v-container class="text-center">
        <h2>Projektträger</h2>
               
        <v-row class="mt-12" style="margin-bottom: 40px;">
          <v-col cols="12" md="6" lg="3" v-for="(promoter,i) in promoters" :key="i">
            <a :href="promoter.link">
              <v-img height="100px" :alt="promoter.alt" contain :src="promoter.img" />
            </a>
          </v-col>
          <!-- <v-col cols="12" md="4">
            <img height="100px" contain src="../assets/logo-kollektiv-von-morgen.png" />
          </v-col> -->
        </v-row>
        <p>Ein Projekt im Programm „Regionale Open Government Labore“ des Bundesministeriums des Innern und für Heimat.</p>
      </v-container>

      <!-- <v-container style="background-color: WhiteSmoke">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        </p>
      </v-container> -->

      <v-container style="margin-top: 30px" >
      
        <v-row justify="center" no-gutters class="pa-2">
          <v-btn href="https://badendbach.gemeindehaus.digital/impressum" text class="mx-2 text-caption">Impressum & Datenschutz</v-btn>
        </v-row>

        <hr style="width: 100%; border-top: 1px black solid; margin-top:20px; margin-bottom:20px" />

        <v-row justify="center">
          <v-col cols="12">
            <v-btn class="my-2" icon  href="https://www.facebook.com/landkreis.marburg.biedenkopf/" target="_blank" aria-label="Icon Facebook" role="img" aria-hidden="false" >
              <v-icon size="24px">mdi-facebook</v-icon>
            </v-btn>
            <v-btn class="my-2" icon href="https://www.instagram.com/landkreis.marburg_biedenkopf" target="_blank">
              <v-icon aria-label="Icon Instagram" role="img" aria-hidden="false" size="24px">mdi-instagram</v-icon>
            </v-btn>
            <v-btn class="my-2" icon href="https://www.youtube.com/channel/UCGez20cBhQEYeZDzOtqXK3Q" target="_blank">
              <v-icon aria-label="Icon Youtube" role="img" aria-hidden="false" size="24px">mdi-youtube</v-icon>
            </v-btn>
            <v-btn class="my-2" icon href="mailto:opengov@marburg-biedenkopf.de" target="_blank">
              <v-icon aria-label="Icon E-Mail" role="img" aria-hidden="false" size="24px">mdi-email</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" sm="6" class="text-body-2">
            <img height="40px" contain alt="Logo des Landkreis Marburg-Biedenkopf" src="../assets/logo-marburg-biedenkopf.svg" />
            <p>
                Landkreis Marburg-Biedenkopf<br />
                Stabsstelle Dezernatsbüro des Landrats<br />
                <br />
                FD Digitale Dienste und Open Government<br />
                <br />
                Im Lichtenholz 60<br />
                35043 Marburg
            </p>
          </v-col>
          <v-col cols="12" sm="6" class="text-body-2 text-right">
            <p>
              Technische Umsetzung <a href="https://we-build.city/" target="_blank">We-Build.city GmbH</a>
            </p>
          </v-col>
        </v-row>

      </v-container>
    </v-footer>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data: () => ({
      promoters: [
      {
        img:require('../assets/logo-marburg-biedenkopf.svg'),
        link:"https://www.marburg-biedenkopf.de",
        alt:"Logo des Landkreises Marburg-Biedenkopf"
      },
      {
        img:require("../assets/logo-bad-endbach.svg"),
        link:'https://bad-endbach.de',
        alt:"Logo der Gemeinde Bad Endbach"
      },
      {
        img:require("../assets/Web_Logo-Breidenbach_V2_ohne_de.png"),
        link:"https://www.breidenbach.de/",
        alt:"Logo der Gemeinde Breidenbach"
      },
      {
        img:require('../assets/logo-opengov.png'),
        link:'https://open-government-kommunen.de',
        alt:"Logo der Regionalen Open Government Labore"
      }

      ]
    })
  }
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

  .FOOTER {
    margin-top: 45px;
  }

</style>